import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Popconfirm, Space, Switch, Typography, notification } from "antd";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../api/auth_header";
import "../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, TIME_DURATION } from "../../../../constants";
import TableMui from "../../../layout/TableMui";
import ModalPR from "./ModalPR";

const DichVuCungCap = ({
    dvName,
    dvId,
    isView,
    QuickSearchToolbar,
    isDichVuKhac,
    reloadNew,
}) => {
    const urlMapping = useMemo(
        () => ({
            "ve-tau": `${API_BASE_URL}ncc/vetau/`,
            "ve-thang-canh": `${API_BASE_URL}ncc/vethangcanh/`,
            visa: `${API_BASE_URL}ncc/dvVisa/`,
            "huong-dan-vien": `${API_BASE_URL}ncc/dvhdv/`,
            "may-bay": `${API_BASE_URL}ncc/dvMayBay/`,
            "ve-thuyen": `${API_BASE_URL}ncc/vethuyen/`,
            "ve-bus": `${API_BASE_URL}ncc/vexebus/`,
            "dich-vu-khac": `${API_BASE_URL}ncc/dvkhac/`,
            "huong-dan-vien-tu-do": `${API_BASE_URL}ncc/dvhdv/`,
        }),
        []
    );
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();

    const urlMap = useMemo(() => {
        return urlMapping[dvName] || null;
    }, [dvName]);

    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };

    useEffect(() => {
        getData();
    }, [dvName, reloadNew]);

    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
            isDichVuKhac,
        };

        axios({
            method: "post",
            url: urlMap + "getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            let giaPhongDetail;
                            switch (dvName) {
                                case "ve-tau":
                                    giaPhongDetail = data[i].tranxVeTauRequests;
                                    break;
                                case "may-bay":
                                    giaPhongDetail =
                                        data[i].tranxDvMayBayTimeRequests;
                                    break;
                                case "ve-thang-canh":
                                    giaPhongDetail =
                                        data[i].tranxVeThangCanhRequests;
                                    break;
                                case "visa":
                                    giaPhongDetail =
                                        data[i].tranxDvVisaTimeRequests;
                                    break;
                                case "huong-dan-vien":
                                    giaPhongDetail =
                                        data[i].tranxDichVuHdvRequests;
                                    break;
                                case "ve-thuyen":
                                    giaPhongDetail =
                                        data[i].tranxVeThuyenRequests;
                                    break;
                                case "ve-bus":
                                    giaPhongDetail =
                                        data[i].tranxVeXeBusRequests;
                                    break;
                                case "dich-vu-khac":
                                    giaPhongDetail =
                                        data[i].tranxDichVuKhacRequests;
                                    break;
                                case "huong-dan-vien-tu-do":
                                    giaPhongDetail =
                                        data[i].tranxDichVuHdvRequests;
                                    break;
                                default:
                                    break;
                            }

                            if (giaPhongDetail.length > 0) {
                                for (
                                    let k = 0;
                                    k < giaPhongDetail.length;
                                    k++
                                ) {
                                    dataLst.push({
                                        stt: dataLst.length + 1,
                                        id: 1 + Math.random() * (100 - 1),
                                        idPK: data[i].id,
                                        nccThongTinChungId:
                                            data[i].nccThongTinChungId,
                                        dmHangHangKhongId:
                                            data[i].dmHangHangKhongId,
                                        hangHangKhong: data[i].hangHangKhong,
                                        maDichVu: data[i].maDichVu,
                                        tenDichVu: data[i].tenDichVu,
                                        dmTyGiaId: data[i].dmTyGiaId,
                                        loaiViSa: data[i].loaiViSa,
                                        loaiTien:
                                            data[i].ngoaiTe ||
                                            data[i].maNgoaiTe ||
                                            data[i].loaiTien,
                                        ghiChu: data[i].ghiChu,
                                        diemDi: data[i].diemDi,
                                        diemDen: data[i].diemDen,
                                        diaDiem: data[i].diaDiem,
                                        ngoaiNgu: data[i].ngoaiNgu,
                                        maChuyenTau: data[i].maChuyenTau,
                                        tranxDvVisaTimeRequests:
                                            data[i].tranxDvVisaTimeRequests
                                                ?.length,
                                        tranxDichVuHdvRequests:
                                            data[i].tranxDichVuHdvRequests
                                                ?.length,
                                        tranxVeTauRequests:
                                            data[i].tranxVeTauRequests?.length,
                                        tranxVeThangCanhRequests:
                                            data[i].tranxVeThangCanhRequests
                                                ?.length,
                                        idDvVisa: giaPhongDetail[k].idDvVisa,
                                        nncDichVuNhId:
                                            giaPhongDetail[k].nncDichVuNhId,
                                        thoiGianApDung:
                                            giaPhongDetail[k].tuNgay +
                                            " - " +
                                            giaPhongDetail[k].denNgay,
                                        tuNgay: giaPhongDetail[k].tuNgay,
                                        denNgay: giaPhongDetail[k].denNgay,
                                        giaNet: giaPhongDetail[k].giaNet,
                                        giaBan: giaPhongDetail[k].giaBan,
                                        visa: giaPhongDetail[k].visa,
                                        foc: giaPhongDetail[k].foc,
                                        ve: giaPhongDetail[k].ve,
                                        trangThaiBooking:
                                            giaPhongDetail[k].trangThaiBooking,
                                        hieuLuc: giaPhongDetail[k].hieuLuc,
                                        dieuKienFoc:(giaPhongDetail[k].foc || 0)
                                             +
                                            " Vé/ " +
                                            (giaPhongDetail[k].ve ||
                                                giaPhongDetail[k].dieuKienFoc ||
                                                giaPhongDetail[k].visa ||
                                                0)
                                             +
                                            " FOC",
                                        idBooking: giaPhongDetail[k].id,
                                    });
                                }
                            } else {
                                dataLst.push({
                                    stt: dataLst.length + 1,
                                    rowKey: i,
                                    loaiViSa: data[i].loaiViSa,
                                    id: 1 + Math.random() * (100 - 1),
                                    idPK: data[i].id,
                                    nccThongTinChungId:
                                        data[i].nccThongTinChungId,
                                    maDichVu: data[i].maDichVu,
                                    tenDichVu: data[i].tenDichVu,
                                    maChuyenTau: data[i].maChuyenTau,
                                    dmTyGiaId: data[i].dmTyGiaId,
                                    maNgoaiTe: data[i].ngoaiTe,
                                    diemDi: data[i].diemDi,
                                    ghiChu: data[i].ghiChu,
                                });
                            }
                            dataLstSearch.push({
                                value:
                                    data[i].id -
                                    data[i].maDichVu -
                                    data[i].tenDichVu,
                            });
                        }
                        // setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                    } else {
                        setDataSource([]);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    // const handleChange = (pagination, filters, sorter) => {
    //     console.log("Various parameters", pagination, filters, sorter);
    //     setFilteredInfo(filters);
    // };
    const handleBorderChange = (record, value) => {
        let values = {
            id: record.idBooking,
            trangThaiBooking: value !== true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: urlMap + "updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setAction(false);
        setIsShow(true);
    };
    const handleDelete = (record) => {
        let apiData = {
            id: record.idPK,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: urlMap + "updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    getData();
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    const handleCancel = () => {
        setIsShow(false);
        setEditingKey(null);
    };
    const handleFinish = async (status, values) => {
        // setIsShow(status);
        setAction(status);
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
            isDichVuKhac: isDichVuKhac,
        };
        try {
            let response = await axios({
                method: "post",
                url: urlMap + "saveData",
                data: data,
                headers: authHeader(),
            });
            if (response.status === 200 && response.data.code === "00") {
                setIsShow(false);
                setAction(status);
                getData();
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
                return true;
            } else {
                setAction(true);
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: TIME_DURATION,
                });
                return false;
            }
        } catch (error) {
            console.log("Error response: ====" + error);
            api["error"]({
                message: t("stour"),
                description: t("edit") + " " + t("noti.error").toLowerCase(),
                duration: TIME_DURATION,
            });
            return false;
        }
    };
    const maPhongs = new Set();
    const stts = new Set();
    const hangPhongs = new Set();
    useEffect(() => {
        maPhongs.clear();
        stts.clear();
        hangPhongs.clear();
    }, [hangPhongs, maPhongs, stts]);
    const [columns, setColumns] = useState([]);
    useEffect(() => {
        let newCol = [
            {
                headerName: t("stt"),
                field: "stt",
                key: "stt",
                width: 60,
                headerAlign: "center",
            },
            {
                headerName: t("res.code"),
                field: "maDichVu",
                key: "maDichVu",
                width: 200,
                align: "center",
                flex: 1,
                headerAlign: "center",
            },
            {
                headerName: t("res.serviceProvided"),
                field: "tenDichVu",
                key: "tenDichVu",
                width: 200,
                align: "center",
                flex: 1,
                headerAlign: "center",
            },
            {
                headerName: t("hotel.priceRoom.applicationTime"),
                field: "thoiGianApDung",
                key: "thoiGianApDung",
                width: 500,
                align: "center",
                flex: 1,
                headerAlign: "center",
            },
            {
                headerName: t("hotel.priceClass.giaNet"),
                field: "giaNet",
                key: "giaNet",
                align: "center",
                flex: 1,
                headerAlign: "center",
                valueFormatter: (params) => {
                    if (params.value == null) {
                        return "";
                    }
                    return params.value.toLocaleString();
                },
            },
            {
                headerName: t("hotel.priceClass.giaBan"),
                field: "giaBan",
                key: "giaBan",
                align: "center",
                flex: 1,
                headerAlign: "center",
                valueFormatter: (params) => {
                    if (params.value == null) {
                        return "";
                    }
                    return params.value.toLocaleString();
                },
            },
            {
                headerName: t("exchangeRate.currency"),
                field: "loaiTien",
                key: "loaiTien",
                align: "center",
                flex: 1,
                headerAlign: "center",
            },
            {
                headerName: t("status"),
                field: "trangThaiBooking",
                key: "trangThaiBooking",
                align: "center",
                flex: 1,
                headerAlign: "center",
                renderCell: ({ value, row }) => {
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={() => handleBorderChange(row, value)}
                        />
                    );
                },
            },
            {
                headerName: t("action"),
                field: "action",
                key: "x",
                responsive: ["md"],
                flex: 1,
                headerAlign: "center",
                renderCell: (record) => {
                    const editable = isEditing(record.row);
                    return (
                        <Space>
                            <Typography.Link>
                                <EyeOutlined
                                    onClick={() => viewRecord(record.row)}
                                />
                            </Typography.Link>
                            <Typography.Link
                                disabled={editingKey !== ""}
                                onClick={() => edit(record.row)}
                            >
                                <EditOutlined style={{}} />
                            </Typography.Link>
                            <Typography.Link>
                                <Popconfirm
                                    headerName={t("deleteTheTask")}
                                    description={t("areYouConfirm")}
                                    okText={t("delete")}
                                    cancelText={t("huy")}
                                    onConfirm={() => handleDelete(record.row)}
                                >
                                    <DeleteOutlined />
                                </Popconfirm>
                            </Typography.Link>
                        </Space>
                    );
                },
                align: "center",
            },
        ];
        if (
            dvName === "ve-bus" ||
            dvName === "may-bay" ||
            dvName === "ve-thuyen" ||
            dvName === "visa" ||
            dvName === "ve-tau" ||
            dvName === "dich-vu-khac"
        ) {
            newCol.splice(6, 0, {
                headerName: t("hotel.roomClass.conditionFoc"),
                field: "dieuKienFoc",
                key: "dieuKienFoc",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        if (dvName === "visa") {
            newCol.splice(3, 0, {
                headerName: t("typeVisa"),
                field: "loaiViSa",
                key: "loaiViSa",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        if (dvName === "ve-thang-canh") {
            newCol.splice(3, 0, {
                headerName: t("location"),
                field: "diaDiem",
                key: "diaDiem",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
            newCol.splice(7, 0, {
                headerName: t("hotel.roomClass.conditionFoc"),
                field: "dieuKienFoc",
                key: "dieuKienFoc",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        if (dvName === "may-bay") {
            newCol.splice(3, 0, {
                headerName: t("destination"),
                field: "diemDen",
                key: "diemDen",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
            newCol.splice(3, 0, {
                headerName: t("point"),
                field: "diemDi",
                key: "diemDi",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
            newCol.splice(3, 0, {
                headerName: t("airlines"),
                field: "hangHangKhong",
                key: "hangHangKhong",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        if (dvName === "ve-tau") {
            newCol.splice(3, 0, {
                headerName: t("destination"),
                field: "diemDen",
                key: "diemDen",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
            newCol.splice(3, 0, {
                headerName: t("point"),
                field: "diemDi",
                key: "diemDi",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
            newCol.splice(3, 0, {
                headerName: t("trainCode"),
                field: "maChuyenTau",
                key: "maChuyenTau",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        if (dvName === "huong-dan-vien") {
            newCol.splice(3, 0, {
                headerName: t("language"),
                field: "ngoaiNgu",
                key: "ngoaiNgu",
                align: "center",
                flex: 1,
                headerAlign: "center",
            });
        }
        setColumns(newCol);
    }, [dvName]);
    // const QuickSearchToolbar = () => {
    //     return (
    //         <Box
    //             sx={{
    //                 p: 0.5,
    //                 pb: 0,
    //             }}
    //         >
    //             <Row>
    //                 <Col xs={24} sm={24} md={12} lg={12} xl={5}>
    //                     <GridToolbarQuickFilter style={{ width: "100%" }} />
    //                 </Col>
    //                 <Col xs={24} sm={24} md={12} lg={12} xl={4}></Col>
    //                 <Col xs={24} sm={24} md={12} lg={12} xl={12}>
    //                     <FormPR
    //                         dataAdd={dataSource}
    //                         dvId={dvId}
    //                         setReload={setReload}
    //                         dvName={dvName}
    //                         urlMap={urlMap}
    //                     />
    //                 </Col>
    //             </Row>
    //         </Box>
    //     );
    // };
    return (
        <div>
            {contextHolder}
            <TableMui
                dataSource={dataSource}
                columns={columns}
                QuickSearchToolbar={QuickSearchToolbar}
                edit={edit}
            />
            <ModalPR
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
                urlMap={urlMap}
                dvId={dvId}
                dvName={dvName}
            />
        </div>
    );
};
export default DichVuCungCap;
