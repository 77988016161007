import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Cascader, Col, Row } from "antd";
import axios from "axios";

import "../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, Ncc_ID } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import FormActionButtonSecond from "../formActionButton/FormActionButtonSecond ";
import { useTranslation } from "react-i18next";

// dynamic import component
const RoomClass = lazy(() =>
    import("../../hotel/addhotel/tabHangPhong/RoomClass")
);
const PriceRoom = lazy(() =>
    import("../../hotel/addhotel/tabGiaPhong/PriceRoom")
);
const TableDvccOto = lazy(() =>
    import("../../oto/addOto/tabDichVuCC/TableDvccOto")
);
const DvNhaHang = lazy(() =>
    import("../../restaurant/addRes/tabDichVuCC/DvNhaHang")
);
const RoomType = lazy(() =>
    import("../../duThuyen/addDuThuyen/tabLoaiPhong/RoomType")
);
const PriceRoom_materal = lazy(() =>
    import("../../duThuyen/addDuThuyen/tabGiaPhong/PriceRoom_materal")
);
const DichVuCungCap = lazy(() => import("../tabDichVuCC/DichVuCungCap"));
const DichVuCC = lazy(() =>
    import("../../landTour/addLandTour/tabDichVuCungCap/DichVuCC")
);

const DichVuKhac = () => {
    const [isReload, setIsReload] = useState(false);
    const path = window.location.pathname.split("/")[2];
    const [dichVu, setDichVu] = useState();
    const [dmDichVu, setDmDichVu] = useState([]);

    const [searchParams] = useSearchParams();
    const location = useLocation();

    const dvId = searchParams.get("id");
    const { t } = useTranslation();

    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/getDataById",
            headers: authHeader(),
            data: {
                id: dvId,
            },
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataDV = [];
                    let data = response.data.data?.dmDichVus;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].id !== Ncc_ID[path]) {
                                if (data[i].id === 1) {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].tenDichVu,
                                        children: [
                                            {
                                                value: 1.1,
                                                label: t("hotel.roomClass"),
                                            },
                                            {
                                                value: 1.2,
                                                label: t("hotel.roomPrice"),
                                            },
                                        ],
                                    });
                                } else if (data[i].id === 4) {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].tenDichVu,
                                        children: [
                                            {
                                                value: 4.1,
                                                label: t("duthuyen.roomPrice"),
                                            },
                                            {
                                                value: 4.2,
                                                label: t("duthuyen.roomPrice"),
                                            },
                                        ],
                                    });
                                } else {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].tenDichVu,
                                    });
                                }
                            }
                        }
                        setDmDichVu(dataDV);
                        setDichVu(dataDV.length > 0 && dataDV[0].value);
                    }
                }
            })
            .catch(function (response) {
                console.error("Error response: ====" + response);
            });
    }, [location.pathname, path, isReload]);

    const handleDv = useCallback((dichVu) => {
        if (dichVu.length === 2) {
            setDichVu(dichVu[1]);
            return;
        }
        setDichVu(dichVu[0]);
    }, []);

    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                </Row>
            </Box>
        );
    };

    const handleRender = (dichVu) => {
        switch (dichVu) {
            case 1.1:
                return (
                    <RoomClass
                        dvId={dvId}
                        isDichVuKhac={true}
                        QuickSearchToolbar={QuickSearchToolbar}
                    />
                );
            case 1.2:
                return (
                    <PriceRoom
                        dvId={dvId}
                        isDichVuKhac={true}
                        QuickSearchToolbar={QuickSearchToolbar}
                    />
                );
            case 2:
                return (
                    <TableDvccOto
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 3:
                return (
                    <DvNhaHang
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 4.1:
                return (
                    <RoomType
                        dvId={dvId}
                        isDichVuKhac={true}
                        QuickSearchToolbar={QuickSearchToolbar}
                        reloadNew={isReload}
                    />
                );
            case 4.2:
                return (
                    // eslint-disable-next-line react/jsx-pascal-case
                    <PriceRoom_materal
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 5:
                return (
                    <DichVuCungCap
                        dvName={"huong-dan-vien"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 6:
                return (
                    <DichVuCungCap
                        dvName={"may-bay"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 7:
                return (
                    <DichVuCungCap
                        dvName={"ve-thuyen"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 8:
                return (
                    <DichVuCungCap
                        dvName={"visa"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 9:
                return (
                    <DichVuCungCap
                        dvName={"ve-thang-canh"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 10:
                return (
                    <DichVuCungCap
                        dvName={"ve-bus"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 11:
                return (
                    <DichVuCungCap
                        dvName={"ve-tau"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );
            case 13:
                return (
                    <DichVuCC
                        dvName={"land-tour"}
                        QuickSearchToolbar={QuickSearchToolbar}
                        dvId={dvId}
                        isDichVuKhac={true}
                        reloadNew={isReload}
                    />
                );

            default:
                return null;
        }
    };

    const displayRender = (labels) => {
        return labels[labels.length - 1];
    };

    const getServiceSlug = useCallback((dichVu) => {
        const serviceMap = {
            1.1: "hotel.roomClass",
            1.2: "hotel.roomPrice",
            2: "oto.dvcc",
            3: "restaurant.dvcc",
            4.1: "duthuyen.roomClass",
            4.2: "duthuyen.roomPrice",
            5: "huong-dan-vien",
            6: "may-bay",
            7: "ve-thuyen",
            8: "visa",
            9: "ve-thang-canh",
            10: "ve-bus",
            11: "ve-tau",
            12: "dich-vu-khac",
            13: "landtour.dvcc",
            14: "huong-dan-vien-tu-do",
        };

        return serviceMap[dichVu] || null;
    }, []);

    return (
        <div>
            <Row
                style={{
                    marginBottom: "10px",
                }}
                justify="space-between" // Thêm justify để tách các phần tử sang hai bên
            >
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    {dmDichVu && dmDichVu.length > 0 && (
                        <Cascader
                            value={dichVu === 1.1 ? [1, 1.1] : [dichVu]}
                            options={dmDichVu}
                            expandTrigger="hover"
                            displayRender={displayRender}
                            onChange={(value) => handleDv(value)}
                            allowClear={false}
                            style={{
                                width: "100%", // Đảm bảo Cascader lấp đầy cột
                            }}
                        />
                    )}
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={9}>
                    <FormActionButtonSecond
                        dvId={dvId}
                        setReload={setIsReload}
                        isDichVuKhac={true}
                        dmDichVuId={Math.round(Number(dichVu))}
                        dichVu={getServiceSlug(dichVu)}
                    />
                </Col>
            </Row>

            <Suspense fallback={<div>Loading...</div>}>
                {!dmDichVu || dmDichVu.length === 0 ? (
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "10px",
                            fontSize: "16px",
                        }}
                    >
                        {t("not.otherServices")}
                    </div>
                ) : (
                    handleRender(dichVu)
                )}
            </Suspense>
        </div>
    );
};
export default DichVuKhac;
