import { Popconfirm, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
import { DeleteOutlined } from "@ant-design/icons";
import CategoryService from "../../../../../api/category.service";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";

const TableModalLT = ({ keyNum, setDataEdit, dataS, dataSum }) => {
    const { t, i18n } = useTranslation();
    const [checkEdit, setCheckEdit] = useState(false);
    const [dataLoaiKhachId, setDataLoaiKhachId] = useState([]);
    //const [dataSource, setDataSource] = useState(dataS || []); // Khởi tạo với dataS nếu có
    const [dataSource, setDataSource] = useState(() => dataS || []);

    useEffect(() => {
        // @ts-ignore
        if (dataS && dataSource.length === 0) {
            setDataSource(dataS); // Cập nhật dataSource khi dataS thay đổi
        }
    }, [dataS]); // Chạy lại khi dataS thay đổi

    useEffect(() => {
        let data = {};
        CategoryService.getDmLoaiKhach(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataLoaiKhachId(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);

    const handleDeleteRow = (row) => {
        setDataSource(dataSource.filter((item) => item.stt !== row.stt));
    };
    const rows = [...dataSource, { id: "SUBTOTAL" }];

    const handleChangeField = (e, stt, field) => {
        const rawValue = e.target.value;
        let value = rawValue;

        // Xử lý riêng với các field số có dấu ,
        if (field === "giaNet" || field === "giaBan") {
            const numericValue = parseFloat(rawValue.replace(/,/g, ""));
            value = isNaN(numericValue) ? "" : numericValue;
        }

        setDataSource((prevData) =>
            prevData.map((item) =>
                item.stt === stt ? { ...item, [field]: value } : item
            )
        );
        // Cập nhật lại dữ liệu trong dataEdit
        setDataEdit({
            key: keyNum,
            data: dataSource.map((item) =>
                item.stt === stt ? { ...item, [field]: value } : item
            ),
        });

        // Nếu field là "dmLoaiKhachId" thì bật cờ chỉnh sửa
        if (field === "dmLoaiKhachId") {
            setCheckEdit(true);
        }
    };

    const createRandomRow = (stt) => {
        setCheckEdit(false);
        // setCheckEditToiDa(false)
        return {
            stt: stt,
            id: "NEW" + Date.now(),
            dmLoaiKhachId: null,
            giaBan: null,
            soKhachToiThieu: null,
            soKhachToiDa: null,
            giaNet: null,
        };
    };
    const handleAddRow = () => {
        setDataSource((prevRows) => {
            const updatedRows = prevRows.map((row, index) => ({
                ...row,
                stt: index + 1,
            })); // Cập nhật lại stt
            return [...updatedRows, createRandomRow(updatedRows.length + 1)];
        });
    };

    const formatNumberForDisplay = (value) => {
        if (value == null || value === "") return "";
        return new Intl.NumberFormat("en-US").format(value);
    };

    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            editable: true,
            align: "center",
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 7;
                }
                return undefined;
            },
            renderCell: ({ row, api }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                cursor: "pointer",
                            }}
                            onClick={handleAddRow}
                        >
                            <AddIcon
                                sx={{ color: "action.active", mr: 1, my: 0.5 }}
                            />
                            <span
                                style={{
                                    marginBottom: "5px",
                                    fontSize: "15px",
                                }}
                            >
                                {t("tour.themSoLuongKhach")}
                            </span>
                        </Box>
                    );
                } else {
                    const index = api.getAllRowIds().indexOf(row.id);
                    return index + 1; // STT bắt đầu từ 1
                }
            },
        },
        {
            field: "dmLoaiKhachId",
            headerName: t("tour.loaiKhach"),
            width: 230,
            align: "center",
            renderCell: ({ value, row }) => {
                return (
                    <TextField
                        select
                        fullWidth
                        name="dmLoaiKhachId"
                        value={value ?? ""}
                        onChange={(e) =>
                            handleChangeField(e, row.stt, "dmLoaiKhachId")
                        }
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: "0 !important",
                                height: "36px",
                                backgroundColor: "transparent",
                                border: "1px solid #ccc",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiSelect-select": {
                                padding: "0 !important",
                                textAlign: "center",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0 !important",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& fieldset": {
                                display: "none !important",
                            },
                        }}
                    >
                        {dataLoaiKhachId.map((option) => (
                            <MenuItem
                                key={option.id}
                                value={option.id}
                                sx={{
                                    fontSize: "14px",
                                    padding: "6px 12px",
                                }}
                            >
                                {option.loai_khach}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),
        },
        {
            field: "soKhachToiThieu",
            headerName: t("tour.soKhachToiThieu"),
            type: "number",
            align: "center",
            // editable: checkEdit,
            width: 200,
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),
            renderCell: ({ value, row }) => {
                return (
                    <TextField
                        type="number"
                        fullWidth
                        disabled={!checkEdit || !row.dmLoaiKhachId}
                        name="soKhachToiThieu"
                        onChange={(e) =>
                            handleChangeField(e, row.stt, "soKhachToiThieu")
                        }
                        value={value}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: "0 !important",
                                height: "36px",
                                backgroundColor: "transparent",
                                border: "1px solid #ccc",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },

                            "& .MuiInputBase-input": {
                                padding: "0 !important",
                                textAlign: "center",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& fieldset": {
                                display: "none !important",
                            },
                        }}
                    ></TextField>
                );
            },
        },
        {
            field: "soKhachToiDa",
            headerName: t("tour.soKhachToiDa"),
            type: "number",
            align: "center",
            width: 200,
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),
            renderCell: ({ value, row }) => {
                // if (row.dmLoaiKhachId === 1 && row.soKhachToiThieu !== undefined) {
                //     return row.soKhachToiThieu;
                // }
                return (
                    <TextField
                        fullWidth
                        type="number"
                        disabled={row.dmLoaiKhachId !== 2}
                        name="soKhachToiDa"
                        onChange={(e) =>
                            handleChangeField(e, row.stt, "soKhachToiDa")
                        }
                        value={row.dmLoaiKhachId === 1 ? "" : value}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: "0 !important",
                                height: "36px",
                                backgroundColor: "transparent",
                                border: "1px solid #ccc",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0 !important",
                                textAlign: "center",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& fieldset": {
                                display: "none !important",
                            },
                        }}
                    ></TextField>
                );
            },
        },
        {
            field: "giaNet",
            headerName: t("hotel.priceClass.giaNet"),
            description: t("hotel.priceClass.giaNet"),
            align: "center",
            width: 160,
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),

            renderCell: ({ value, row }) => {
                // const formattedValue =
                //     value == null || value === ""
                //         ? ""
                //         : new Intl.NumberFormat("en-US").format(value);

                return (
                    <TextField
                        fullWidth
                        type="text"
                        name="giaNet"
                        onChange={(e) =>
                            handleChangeField(e, row.stt, "giaNet")
                        }
                        value={formatNumberForDisplay(value) ?? ""}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: "0 !important",
                                height: "36px",
                                backgroundColor: "transparent",
                                border: "1px solid #ccc",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0 !important",
                                textAlign: "center",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& fieldset": {
                                display: "none !important",
                            },
                        }}
                    />
                );
            },
        },

        {
            field: "giaBan",
            headerName: t("hotel.priceClass.giaBan"),
            description: t("hotel.priceClass.giaBan"),
            align: "center",
            width: 160,
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),
            renderCell: ({ value, row }) => {
                // const formattedValue =
                //     value == null || value === ""
                //         ? ""
                //         : new Intl.NumberFormat("en-US").format(value);

                return (
                    <TextField
                        fullWidth
                        type="text"
                        name="giaBan"
                        onChange={(e) =>
                            handleChangeField(e, row.stt, "giaBan")
                        }
                        value={formatNumberForDisplay(value) ?? ""}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: "0 !important",
                                height: "36px",
                                backgroundColor: "transparent",
                                border: "1px solid #ccc",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0 !important",
                                textAlign: "center",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            "& fieldset": {
                                display: "none !important",
                            },
                        }}
                    />
                );
            },
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            align: "center",
            sortable: false,
            width: 100,
            colSpan: ({ row }) => (row.id === "SUBTOTAL" ? 0 : undefined),
            renderCell: (record) => {
                if (record.row.id !== "SUBTOTAL") {
                    return (
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteRow(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    );
                }
            },
        },
    ];
    const processRowUpdate = (row) => {
        setDataSource(
            dataSource.map((dt) => {
                if (dt.id === row.id) return row;
                else return dt;
            })
        );
        //let data =[];
        //data.push()
        setDataEdit({
            key: keyNum,
            data: dataSource.map((dt) => {
                if (dt.id === row.id) return row;
                else return dt;
            }),
        });
    };
    return (
        <>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                processRowUpdate={processRowUpdate}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                localeText={
                    i18n.language === "vi"
                        ? viVN.components.MuiDataGrid.defaultProps.localeText
                        : enUS
                }
                sx={{
                    // Header
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                        whiteSpace: "normal",
                        textAlign: "center",
                        width: "100%",
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        justifyContent: "center",
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainerContent": {
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                    },

                    // Cell borders
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        padding: "8px",
                    },

                    // Remove last cell border in row
                    "& .MuiDataGrid-row": {
                        "&:last-child .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                    },

                    // Remove last column right border
                    "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-row": {
                        "& .MuiDataGrid-cell:last-of-type, & .MuiDataGrid-columnHeader:last-of-type":
                            {
                                borderRight: "none",
                            },
                    },
                }}
            />
        </>
    );
};
export default TableModalLT;
